// 成本管理相关接口
import request from "@/request/request";

// 课程成本列表
export const Coursecost = (params) => request.post(`/agent/admin/business/coursecost`, params);

// 会员卡成本列表
export const UsermemBercost = (params) => request.post(`/agent/admin/business/usermembercost`, params);

// 获取直销课程商品列表
export const PCoursecost = (params) => request.post(`/agent/admin/product/coursecost`, params);

// 获取直销会员商品列表
export const PUsermembercost = (params) => request.post(`/agent/admin/product/usermembercost`, params);

// 保存商品成本
export const Saveproductcost = (params) => request.post(`/agent/admin/product/saveproductcost`, params);

// 保存业务成本
export const SaveBusinesscost = (params) => request.post(`/agent/admin/business/savebusinesscost`, params);

// 过滤课程列表
export const Fltercourselist = (params) => request.post(`/agent/admin/business/filtercourselist`, params);

// 过滤会员列表
export const Filtermemberlist = (params) => request.post(`/agent/admin/business/filtermemberlist`, params);

// 过滤会员列表
export const Filterbusiness = (params) => request.post(`/agent/admin/business/filterbusiness`, params);