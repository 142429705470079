<template>
    <!-- 菜单管理页面 -->
    <div class="box">
        <!-- 顶部 -->
        <div class="header">
            <div class="title">过滤课程设置</div>
        </div>
        <div class="conbox">
            <a-tabs v-model="activeKey" @change="activeKeyChange">
                <a-tab-pane :key="1" tab="课程">
                    <div class="content">
                        <a-form layout="inline" :model="formState">
                            <a-form-item label="课程名称">
                                <a-input v-model="formState.value" placeholder="请输入课程名称" style="width: 248px"/>
                            </a-form-item>
                            <a-form-item label="状态">
                                <a-select v-model="formState.putawayFlag" allowClear placeholder="请选择上架状态" style="width: 248px">
                                    <a-select-option :value="-1">全部</a-select-option>
                                    <a-select-option :value="0">已下架</a-select-option>
                                    <a-select-option :value="1">定时上架</a-select-option>
                                    <a-select-option :value="2">已上架</a-select-option>
                                    <a-select-option :value="3">暂不上架</a-select-option>
                                </a-select>
                            </a-form-item>
                            <a-form-item>
                                <a-button type="primary" html-type="submit" class="margin_right10" @click="search">搜索</a-button>
                            </a-form-item>
                        </a-form>
                    </div>
                    <div class="tableStyle">
                        <a-table :columns="columns" :data-source="tabledData" :row-key="record => record.courseNo" :pagination="false" :loading="tableLoading" bordered ref="courseTable">
                            <span slot="enableFlag" slot-scope="enableFlag, record">
                                <a-checkbox v-model="record.enableFlag" v-if="enableFlagShow" @change="enableFlagChange(record)"></a-checkbox>
							</span>
                            <span slot="putawayFlag" slot-scope="putawayFlag, record">
                                <span v-if="putawayFlag == 0">已下架</span>
                                <span v-else-if="putawayFlag == 1">定时上架</span>
                                <span v-else-if="putawayFlag == 2">已上架</span>
                                <span v-else-if="putawayFlag == 3">暂不上架</span>
							</span>
                        </a-table>
                    </div>
                </a-tab-pane>
                <a-tab-pane :key="2" tab="会员课程">
                    <div class="content">
                        <a-form layout="inline" :model="formState">
                            <a-form-item label="会员名称">
                                <a-input v-model="formState.value" placeholder="请输入会员名称" style="width: 248px"/>
                            </a-form-item>
                            <a-form-item label="状态">
                                <a-select v-model="formState.forbiddenStatus" allowClear placeholder="请选择上架状态" style="width: 248px">
                                    <a-select-option :value="0">全部</a-select-option>
                                    <a-select-option :value="1">启用</a-select-option>
                                    <a-select-option :value="2">禁用</a-select-option>
                                </a-select>
                            </a-form-item>
                            <a-form-item>
                                <a-button type="primary" html-type="submit" class="margin_right10" @click="search">搜索</a-button>
                            </a-form-item>
                        </a-form>
                    </div>
                    <div class="tableStyle">
                        <a-table :columns="vipcolumns" :data-source="vipTabledData" :row-key="record => record.memberNo" :pagination="false" :loading="tableLoading" bordered>
                            <span slot="enableFlag" slot-scope="enableFlag, record">
                                <a-checkbox v-model="record.enableFlag" v-if="enableFlagVipShow" @change="enableFlagChangeVip(record)"></a-checkbox>
							</span>
                            <span slot="forbiddenStatus" slot-scope="forbiddenStatus, record">
                                <span v-if="forbiddenStatus == 1">启用</span>
                                <span v-else-if="forbiddenStatus == 2">禁用</span>
							</span>
                        </a-table>
                    </div>
                </a-tab-pane>
            </a-tabs>
            <div class="page" v-if="pageShow">
                <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn"/>
            </div>
            <router-view/>
        </div>
    </div>
</template>

<script>
import {message, Modal} from "ant-design-vue";
import MyPagination from "@/components/pagination/MyPagination";
import {
    Fltercourselist,
    Filtermemberlist,
    Filterbusiness
} from "@/request/api/costManage";

export default {
    components: {MyPagination},
    created() {
        this.courseListFn();
    },
    data() {
        return {
            enableFlagShow: false,
            enableFlagVipShow:false,
            pageShow:false,
            pageNo: 1,
            pageSize: '',
            count: 0,

            activeKey: 1,
            current: 1,
            tableLoading: false, // 表格的loading图
            // 搜索数据
            formState: {
                value: '',
                putawayFlag: undefined,
                forbiddenStatus:undefined,
            },

            // 表格数据
            columns: [
                {
                    title: "",
                    dataIndex: "enableFlag",
                    key: "enableFlag",
                    width: "50px",
                    scopedSlots: {customRender: "enableFlag"},
                },
                {
                    title: "课程名称",
                    dataIndex: "courseName",
                    width: "25%",
                    key: "courseName",
                },
                {
                    title: "状态",
                    dataIndex: "putawayFlag",
                    width: "25%",
                    key: "putawayFlag",
                    scopedSlots: {customRender: "putawayFlag"},
                },
                {
                    title: "课程类型",
                    dataIndex: "courseType",
                    width: "25%",
                    key: "courseType",
                },
                {
                    title: "定价",
                    dataIndex: "price",
                    width: "25%",
                    key: "price",
                },
            ],
            tabledData: [],

            // vip课程表格数据
            vipcolumns: [
                {
                    title: "",
                    dataIndex: "enableFlag",
                    key: "enableFlag",
                    width: "50px",
                    scopedSlots: {customRender: "enableFlag"},
                },
                {
                    title: "会员名称",
                    dataIndex: "memberName",
                    width: "40%",
                    key: "memberNames",
                },
                {
                    title: "状态",
                    dataIndex: "forbiddenStatus",
                    width: "25%",
                    key: "forbiddenStatus",
                    scopedSlots: {customRender: "forbiddenStatus"},
                },
                {
                    title: "定价",
                    dataIndex: "price",
                    width: "25%",
                    key: "price",
                },
            ],
            vipTabledData: [],

            usedVal: '',
            newVal: '',
        };
    },
    methods: {
        enableFlagChange (record) {
            Filterbusiness({
                businessNo: record.courseNo,
                businessType:1,
                enableFlag: record.enableFlag == true ? 1 : 0
            }).then(({ code, data, msg }) => {
                if (code === 200) {
                    message.success('保存成功！')
                } else {
                    message.error(msg)
                }
            });
        },

        enableFlagChangeVip (record) {
            Filterbusiness({
                businessNo: record.memberNo,
                businessType:2,
                enableFlag: record.enableFlag == true ? 1 : 0
            }).then(({ code, data, msg }) => {
                if (code === 200) {
                    message.success('保存成功！')
                } else {
                    message.error(msg)
                }
            });
        },

        activeKeyChange(activeKey) {
            this.pageShow = false
            this.usedVal = ''
            this.newVal = ''
            this.pageNo = 1
            this.count = 0
            this.formState.value = ''
            this.formState.putawayFlag = undefined
            this.formState.forbiddenStatus = undefined
            this.courseListFn()
        },

        search() {
            this.pageNo = 1
            this.tabledData = []
            this.vipTabledData = []
            this.courseListFn();
        },

        // 分页切换页数
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            this.courseListFn();
        },

        // 请求列表数据
        courseListFn () {
            this.enableFlagShow = false
            this.enableFlagVipShow = false
            if (this.activeKey == 1) {
                Fltercourselist({
                    page: this.pageNo,
                    putawayFlag:this.formState.putawayFlag,
                    name: this.formState.value
                }).then(({code, data}) => {
                    if (code === 200) {
                        this.$nextTick(() => {
                            this.enableFlagShow = true
                        })
                        this.pageShow = true
                        this.tableLoading = false;
                        this.tabledData = data.list;
                        this.count = data.count;
                    }
                });
            } else if (this.activeKey == 2) {
                Filtermemberlist({
                    page: this.pageNo,
                    forbiddenStatus:this.formState.forbiddenStatus,
                    name: this.formState.value
                }).then(({code, data}) => {
                    if (code === 200) {
                        this.$nextTick(()=>{
                            this.enableFlagVipShow = true
                        })
                        this.pageShow = true
                        this.tableLoading = false;
                        this.vipTabledData = data.list;
                        this.count = data.count;
                    }
                });
            }
        },
    },
};
</script>

<style lang="less" scoped>
.box {
    min-height: 100%;
    background-color: #fff;

    .header {
        width: 100%;
        background-color: #fff;

        .title {
            padding: 14px 16px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);;
        }

        .content {
            padding: 14px 16px;
        }
    }

    .conbox {
        padding: 14px 16px;
    }

    .tableStyle {
        background-color: #fff;
        padding: 16px 0;
    }
    .page {
        display: flex;
        justify-content: flex-end;
        padding-right: 10px;
    }
}
</style>
