<template>
    <!-- 菜单管理页面 -->
    <div class="box">
        <!-- 顶部 -->
        <div class="header">
            <div class="title">成本设置</div>
        </div>
        <div class="conbox">
            <a-tabs v-model="activeKey" @change="activeKeyChange">
                <a-tab-pane :key="1" tab="课程">
                    <div class="content">
                        <a-form layout="inline" :model="formState">
                            <a-form-item label="课程名称">
                                <a-input v-model:value="formState.value" placeholder="请输入课程名称" style="width: 248px"/>
                            </a-form-item>
                            <a-form-item>
                                <a-button type="primary" html-type="submit" class="margin_right10" @click="search">搜索</a-button>
                            </a-form-item>
                        </a-form>
                    </div>
                    <div class="tableStyle">
                        <a-table :columns="columns" :data-source="tabledData" :row-key="record => record.courseNo"
                                 :pagination="false" :loading="tableLoading" bordered>
                            <span slot="cost" slot-scope="cost, record">
								<a-input-number controls="false" :min="0" v-model="record.cost" placeholder="请输入成本（必填）" @change="handleCostChange(record)" @focus="focusVal(record)" @blur="blurVal(record)" style="width:100%"/>
							</span>
                        </a-table>
                    </div>
                </a-tab-pane>
                <a-tab-pane :key="2" tab="会员课程">
                    <div class="content">
                        <a-form layout="inline" :model="formState">
                            <a-form-item label="会员名称">
                                <a-input v-model:value="formState.value" placeholder="请输入会员名称" style="width: 248px"/>
                            </a-form-item>
                            <a-form-item>
                                <a-button type="primary" html-type="submit" class="margin_right10" @click="search">搜索</a-button>
                            </a-form-item>
                        </a-form>
                    </div>
                    <div class="tableStyle">
                        <a-table :columns="vipcolumns" :data-source="vipTabledData" :row-key="record => record.memberNo"
                                 :pagination="false" :loading="tableLoading" bordered>
                            <span slot="cost" slot-scope="cost, record">
								<a-input-number controls="false" :min="0" v-model="record.cost" placeholder="请输入成本（必填）" @change="handleCostChange(record)" @focus="focusVal(record)" @blur="blurVal(record)" style="width:100%"/>
							</span>
                        </a-table>
                    </div>
                </a-tab-pane>
                <a-tab-pane :key="3" tab="直销课程">
                    <div class="content">
                        <a-form layout="inline" :model="formState">
                            <a-form-item label="直销课程名称">
                                <a-input v-model:value="formState.value" placeholder="请输入直销课程名称" style="width: 248px"/>
                            </a-form-item>
                            <a-form-item>
                                <a-button type="primary" html-type="submit" class="margin_right10" @click="search">搜索</a-button>
                            </a-form-item>
                        </a-form>
                    </div>
                    <div class="tableStyle">
                        <a-table :columns="directSaleColumns" :data-source="directSaleTabledData"
                                 :row-key="record => record.productId" :pagination="false" :loading="tableLoading"
                                 bordered>
                            <span slot="cost" slot-scope="cost, record">
								<a-input-number controls="false" :min="0" v-model="record.cost" placeholder="请输入成本（必填）" @change="handleCostChange(record)" @focus="focusVal(record)" @blur="blurVal(record)" style="width:100%"/>
							</span>
                        </a-table>
                    </div>
                </a-tab-pane>
                <a-tab-pane :key="4" tab="直销会员">
                    <a-form layout="inline" :model="formState">
                        <a-form-item label="直销会员名称">
                            <a-input v-model:value="formState.value" placeholder="请输入直销会员名称" style="width: 248px"/>
                        </a-form-item>
                        <a-form-item>
                            <a-button type="primary" html-type="submit" class="margin_right10" @click="search">搜索</a-button>
                        </a-form-item>
                    </a-form>
                    <div class="tableStyle">
                        <a-table :columns="directSaleVipColumns" :data-source="directSaleVipTabledData" :row-key="record => record.productId" :pagination="false" :loading="tableLoading" bordered>
                            <span slot="cost" slot-scope="cost, record">
								<a-input-number controls="false" :min="0" v-model="record.cost" placeholder="请输入成本（必填）" @change="handleCostChange(record)" @focus="focusVal(record)" @blur="blurVal(record)" style="width:100%"/>
							</span>
                        </a-table>
                    </div>
                </a-tab-pane>
            </a-tabs>
            <div class="page" v-if="pageShow">
                <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn"/>
            </div>
            <router-view/>
        </div>
    </div>
</template>

<script>
import {message, Modal} from "ant-design-vue";
import MyPagination from "@/components/pagination/MyPagination";
import {
    Coursecost,
    UsermemBercost,
    PCoursecost,
    PUsermembercost,
    SaveBusinesscost,
    Saveproductcost
} from "@/request/api/costManage";

export default {
    components: {MyPagination},
    created() {
        this.courseListFn();
    },
    data() {
        return {
            pageShow:false,
            pageNo: 1,
            pageSize: '',
            count: 0,

            activeKey: 1,
            current: 1,
            tableLoading: false, // 表格的loading图
            // 搜索数据
            formState: {
                value: '',
            },

            // 表格数据
            columns: [
                {
                    title: "课程名称",
                    dataIndex: "courseName",
                    width: "40%",
                    key: "courseName",
                },
                {
                    title: "价格",
                    dataIndex: "price",
                    width: "25%",
                    key: "price",
                },
                {
                    title: "成本",
                    dataIndex: "cost",
                    width: "25%",
                    key: "cost",
                    scopedSlots: {customRender: "cost"},
                },
            ],
            tabledData: [],

            // vip课程表格数据
            vipcolumns: [
                {
                    title: "课程名称",
                    dataIndex: "memberName",
                    width: "40%",
                    key: "memberNames",
                },
                {
                    title: "价格",
                    dataIndex: "price",
                    width: "25%",
                    key: "price",
                },
                {
                    title: "成本",
                    dataIndex: "cost",
                    width: "25%",
                    key: "cost",
                    scopedSlots: {customRender: "cost"},
                },
            ],
            vipTabledData: [],

            // 直销课程表格数据
            directSaleColumns: [
                {
                    title: "直销课程名称",
                    dataIndex: "productName",
                    width: "40%",
                    key: "productName",
                },
                {
                    title: "价格",
                    dataIndex: "price",
                    width: "25%",
                    key: "price",
                },
                {
                    title: "成本",
                    dataIndex: "cost",
                    width: "25%",
                    key: "cost",
                    scopedSlots: {customRender: "cost"},
                },
            ],
            directSaleTabledData: [],

            // 直销vip表格数据
            directSaleVipColumns: [
                {
                    title: "直销会员名称",
                    dataIndex: "productName",
                    width: "40%",
                    key: "productName",
                },
                {
                    title: "价格",
                    dataIndex: "price",
                    width: "25%",
                    key: "price",
                },
                {
                    title: "成本",
                    dataIndex: "cost",
                    width: "25%",
                    key: "cost",
                    scopedSlots: {customRender: "cost"},
                },
            ],
            directSaleVipTabledData: [],

            usedVal: '',
            newVal: '',
        };
    },
    methods: {
        activeKeyChange(activeKey) {
            this.pageShow = false
            this.usedVal = ''
            this.newVal = ''
            this.pageNo = 1
            this.count = 0
            this.formState.value = ''
            this.courseListFn()
        },

        search() {
            this.pageNo = 1
            this.courseListFn();
        },
        // 分页切换页数
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            this.courseListFn();
        },
        // 点击申请提现
        editClick(data) {
            this.$router.push(`/agenManage/upertubeManage/AgenEdit/${data.id}`);
        },

        // 获取表格id
        onChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys
        },

        // 请求列表数据
        courseListFn() {
            if (this.activeKey == 1) {
                Coursecost({
                    page: this.pageNo,
                    name: this.formState.value
                }).then(({code, data}) => {
                    if (code === 200) {
                        this.pageShow = true
                        this.tableLoading = false;
                        this.tabledData = data.list;
                        this.count = data.count;
                    }
                });
            } else if (this.activeKey == 2) {
                UsermemBercost({
                    page: this.pageNo,
                    name: this.formState.value
                }).then(({code, data}) => {
                    if (code === 200) {
                        this.pageShow = true
                        this.tableLoading = false;
                        this.vipTabledData = data.list;
                        this.count = data.count;
                    }
                });
            } else if (this.activeKey == 3) {
                PCoursecost({
                    page: this.pageNo,
                    name: this.formState.value
                }).then(({code, data}) => {
                    if (code === 200) {
                        this.pageShow = true
                        this.tableLoading = false;
                        this.directSaleTabledData = data.list;
                        this.count = data.count;
                    }
                });
            } else {
                PUsermembercost({
                    page: this.pageNo,
                    name: this.formState.value
                }).then(({code, data}) => {
                    if (code === 200) {
                        this.pageShow = true
                        this.tableLoading = false;
                        this.directSaleVipTabledData = data.list;
                        this.count = data.count;
                    }
                });
            }
        },

        focusVal(record) {
            this.usedVal = record.cost
        },

        handleCostChange(record) {
        },

        blurVal(record) {
            this.newVal = record.cost
            if (this.usedVal != this.newVal && record.cost) {
                if (this.activeKey == 1 || this.activeKey == 2) {
                    SaveBusinesscost({
                        businessNo: this.activeKey == 1 ? record.courseNo : record.memberNo,
                        businessType: this.activeKey,
                        cost: record.cost * 1
                    }).then(({code, data}) => {
                        if (code === 200) {
                            message.success("修改成功")
                        } else {
                            message.error("修改格式错误")
                        }
                    });
                } else {
                    Saveproductcost({
                        productId: record.productId,
                        productType: this.activeKey == 3 ? 1 : this.activeKey == 4 ? 2 : '',
                        cost: record.cost * 1
                    }).then(({code, data}) => {
                        if (code === 200) {
                            message.success("修改成功")
                        } else {
                            message.error("修改格式错误")
                        }
                    });
                }
            }
        }
    },
};
</script>

<style lang="less" scoped>
.box {
    min-height: 100%;
    background-color: #fff;

    .header {
        width: 100%;
        background-color: #fff;

        .title {
            padding: 14px 16px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);;
        }

        .content {
            padding: 14px 16px;
        }
    }

    .conbox {
        padding: 14px 16px;
    }

    .tableStyle {
        background-color: #fff;
        padding: 16px 0;
    }
    .page {
        display: flex;
        justify-content: flex-end;
        padding-right: 10px;
    }
}
</style>
